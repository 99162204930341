import React from "react"
import { Helmet } from "react-helmet"
import "./about.css"
import Back from "../common/back/Back"
import AboutCard from "./AboutCard"

const About = () => {
  return (
    <>
      <Helmet>
        <title>À propos - Logiciel CRM</title>
        <meta name="description" content="En savoir plus sur notre solution CRM et comment elle peut transformer votre gestion de la relation client." />
        <meta name="keywords" content="logiciel CRM, solution CRM, gestion de la relation client, CRM pour entreprises, CRM en ligne, CRM cloud, CRM SaaS, CRM pour petites entreprises, meilleur logiciel CRM, système CRM" />
      </Helmet>
      <Back title='À propos' />
      <AboutCard />
    </>
  )
}

export default About
