import React from "react"
import { Helmet } from "react-helmet"
import Back from "../common/back/Back"
import CoursesCard from "./CoursesCard"
import OnlineCourses from "./OnlineCourses"

const CourseHome = () => {
  return (
    <>
      <Helmet>
        <title>Modules - Logiciel CRM</title>
        <meta name="description" content="Découvrez nos formations pour maîtriser notre logiciel CRM et améliorer la gestion de la relation client dans votre entreprise." />
        <meta name="keywords" content="formations CRM, apprendre CRM, gestion de la relation client, CRM pour entreprises, formation en ligne CRM, tutoriels CRM, cours CRM" />
      </Helmet>
      <Back title='Modules' />
      <CoursesCard />
      <OnlineCourses />
    </>
  )
}

export default CourseHome
