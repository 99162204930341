import "./App.css";
import Header from "./components/common/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/about/About";
import CourseHome from "./components/allcourses/CourseHome";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Router>
        <Helmet>
          <title>Solution Logiciel CRM - Gestion de la Relation Client</title>
          <meta name="description" content="Découvrez notre logiciel CRM, une solution complète pour la gestion de la relation client, l'automatisation des processus, et l'optimisation des ventes et du marketing." />
          <meta name="keywords" content="logiciel CRM, solution CRM, gestion de la relation client, CRM pour entreprises, CRM en ligne, CRM cloud, CRM SaaS, CRM pour petites entreprises, meilleur logiciel CRM, système CRM" />
          <link rel="canonical" href="https://www.kmsforces.com" />
        </Helmet>
        <Header />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/courses' element={<CourseHome />} />
          <Route exact path='/contact' element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
