import React from "react"
import { Helmet } from "react-helmet"
import AboutCard from "../about/AboutCard"
import HAbout from "./HAbout"
import Hero from "./hero/Hero"
import Hprice from "./Hprice"
import Testimonal from "./testimonal/Testimonal"

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Page d'accueil - Logiciel CRM pour Entreprises et PME</title>
        <meta name="description" content="Notre logiciel CRM aide les entreprises et les PME à améliorer la relation client, augmenter les ventes, et optimiser le service client avec des fonctionnalités avancées de gestion et d'automatisation." />
        <meta name="keywords" content="logiciel CRM, solution CRM, gestion de la relation client, CRM pour entreprises, CRM en ligne, CRM cloud, CRM SaaS, CRM pour petites entreprises, meilleur logiciel CRM, système CRM" />
      </Helmet>
      <Hero />
      <AboutCard />
      <HAbout />
      <Testimonal />
      <Hprice />
    </>
  )
}

export default Home